import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import arbLogo from '../../assets/images/arb-icon.svg'

const ARB_DEFAULT_IMG = arbLogo as string

const ARBTestnetTokens: ITokenDict = {
  [NetworkCurrencyEnum.eth]: {
    "title": "Ether",
    "img": "https://etherscan.io/images/svg/brands/ethereum-original.svg",
    "rate": "$3580.00",
    "address": undefined,
    "link": "https://basescan.org/",
    "group": `Base Currency (${NetworkCurrencyEnum.eth})`,
    "website": "https://www.base.org/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.eth
  },
  EEL: {
    "title": "Eel Token",
    "img": arbLogo,
    "rate": "$1.00",
    "address": "0xfAAdAf873658fdfA5B94f8E2028A2ced42E81D7e",
    "link": "https://sepolia.basescan.org/token/0xfAAdAf873658fdfA5B94f8E2028A2ced42E81D7e",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "EEL"
  },
  SHRP: {
    "title": "Shrimp Token",
    "img": arbLogo,
    "rate": "$1.00",
    "address": "0xC7A3cA5cf73d5be12EaFAbF9A5175bBfcBfB1147",
    "link": "https://sepolia.basescan.org/address/0xC7A3cA5cf73d5be12EaFAbF9A5175bBfcBfB1147",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "SHRP"
  },
}

export {ARBTestnetTokens, ARB_DEFAULT_IMG}