import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import optimismLogo from '../../assets/images/optimism-icon.svg';

const OPT_DEFAULT_IMG = optimismLogo as string

const OPTTestnetTokens: ITokenDict = {
  [NetworkCurrencyEnum.eth]: {
    "title": "Ether",
    "img": "https://etherscan.io/images/svg/brands/ethereum-original.svg",
    "rate": "$3580.00",
    "address": undefined,
    "link": "https://basescan.org/",
    "group": `Base Currency (${NetworkCurrencyEnum.eth})`,
    "website": "https://www.base.org/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.eth
  },
  SSN: {
    "title": "Susano Token",
    "img": optimismLogo,
    "rate": "$1.00",
    "address": "0x0Cd482eBD2EB8DE1DcD9F23CB8E3F05146748149",
    "link": "https://sepolia-optimism.etherscan.io/token/0x0Cd482eBD2EB8DE1DcD9F23CB8E3F05146748149",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "SSN"
  },
  AMT: {
    "title": "Amaterasu Token",
    "img": optimismLogo,
    "rate": "$1.00",
    "address": "0x9FCA6680dc35E41Bdc2F910EFD7600aD3Af6fc14",
    "link": "https://sepolia-optimism.etherscan.io/address/0x9FCA6680dc35E41Bdc2F910EFD7600aD3Af6fc14",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "AMT"
  },
}

export {OPTTestnetTokens, OPT_DEFAULT_IMG}