import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import avalancheLogo from '../../assets/images/avalanche-icon.svg';

const AVAX_DEFAULT_IMG = avalancheLogo as string

const AVAXTestnetTokens: ITokenDict = {
  [NetworkCurrencyEnum.avax]: {
    "title": "AVAX",
    "img": "https://snowscan.xyz/assets/avax/images/svg/logos/token-light.svg",
    "rate": "$36.38",
    "address": undefined,
    "link": "https://snowscan.xyz/",
    "group": `Base Currency (${NetworkCurrencyEnum.avax})`,
    "website": "https://www.avax.network/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.avax
  },
  INR: {
    "title": "Inari Token",
    "img": avalancheLogo,
    "rate": "$1.00",
    "address": "0x9a15AA8c67C61CC55F058f7606E37B937a097d4d",
    "link": "https://testnet.snowscan.xyz/address/0x9a15AA8c67C61CC55F058f7606E37B937a097d4d",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "INR"
  },
  RJN: {
    "title": "Raijin Token",
    "img": avalancheLogo,
    "rate": "$1.00",
    "address": "0x6cE56FB40B2e20D364465C4239632cBCF05495cF",
    "link": "https://testnet.snowscan.xyz/address/0x6cE56FB40B2e20D364465C4239632cBCF05495cF",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "RJN"
  },
}

export {AVAXTestnetTokens, AVAX_DEFAULT_IMG}