import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import polygonLogo from '../../assets/images/pol-icon.svg';

const POL_DEFAULT_IMG = polygonLogo as string

const POLTestnetTokens: ITokenDict = {
  [NetworkCurrencyEnum.pol]: {
    "title": "Polygon",
    "img": "https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg",
    "rate": "$0.45",
    "address": undefined,
    "link": "https://polygonscan.com/",
    "group": `Base Currency (${NetworkCurrencyEnum.pol})`,
    "website": "https://polygon.technology/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.pol
  },
  EBS: {
    "title": "Ebisu Token",
    "img": polygonLogo,
    "rate": "$1.00",
    "address": "0x15272CFC155c6B9aBf2B2099aE4B29094e6E8D9A",
    "link": "https://amoy.polygonscan.com/token/0x15272CFC155c6B9aBf2B2099aE4B29094e6E8D9A",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 18,
    "symbol": "EBS"
  },
  FJN: {
    "title": "Fujin Token",
    "img": polygonLogo,
    "rate": "$1.00",
    "address": "0xf258CC03911A5f5066B31a6cc87B4D3CFD226576",
    "link": "https://amoy.polygonscan.com/address/0xf258CC03911A5f5066B31a6cc87B4D3CFD226576",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 18,
    "symbol": "FJN"
  },
}

export {POLTestnetTokens, POL_DEFAULT_IMG}